/* Center the login form within the page */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f9fc;
  /* margin: 0 auto; */
}

/* Style the form container */
.login-form {
  background-color: #ffffff;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

/* Heading style */
.login-form h2 {
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
}

/* Input field styles */
.login-form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

/* Input field focus effect */
.login-form input:focus {
  border-color: #1890ff;
  outline: none;
}

/* Button styling */
.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.login-form button:hover {
  background-color: #40a9ff;
}

/* Add some spacing between form elements */
.login-form input + input {
  margin-top: 15px;
}

.signup-link {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #333;

    color: #1890ff;
    text-decoration: none;
}
