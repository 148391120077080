/* General App Layout */
.app {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f4f6f8;
}

/* Left Sidebar Styling */
.left-sidebar {
  width: 25%;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

/* Right Panel Styling (Chat Area) */
.right-panel {
  width: 75%;
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
}

/* Message Item Styling */
.message-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f1f1f1;
}

.message-item strong {
  display: block;
  color: #555;
  margin-bottom: 5px;
}

/* Message Form Styling */
.message-form {
  display: flex;
  padding: 10px 20px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
}

.message-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
}

.message-form button {
  padding: 10px 15px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.message-form button:hover {
  background-color: #40a9ff;
}

/* Logout Button Styling */
.logout-button {
  align-self: flex-end;
  padding: 5px 15px;
  margin-bottom: 20px;
  background-color: #ff5c5c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #ff7878;
}
