.message-list {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message-item {
  margin-bottom: 5px;
}
/* ===== Base Button Styles ===== */
.t-button {
  /* Positioning */
  display: block;
  margin: 10px auto;

  /* Sizing */
  flex: 1;
  max-width: 180px;
  padding: 10px 15px;

  /* Styling */
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  color: #fff; /* White text for contrast */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effects */
}

/* ===== Specific Button Types ===== */
.transcribe-button {
  background-color: #007bff; /* Primary blue */
}

.translate-button {
  background-color: #28a745; /* Primary green */
}

.visualize-button {
  background-color: #ffc107; /* Yellow background */
  color: #212529; /* Dark text for better contrast */
}

/* ===== Hover States ===== */
.transcribe-button:hover {
  background-color: #0056b3; /* Darker blue */
  transform: translateY(-2px); /* Lift effect */
}

.translate-button:hover {
  background-color: #218838; /* Darker green */
  transform: translateY(-2px);
}

.visualize-button:hover {
  background-color: #e0a800; /* Darker yellow */
  transform: translateY(-2px);
}

/* ===== Disabled States ===== */
.t-button:disabled {
  background-color: #d6d6d6; /* Greyed out */
  cursor: not-allowed;
  color: #999; /* Faded text color */
}

.button-group {
  max-width: 580px;
  display: flex; /* Use Flexbox for horizontal layout */
  justify-content: center; /* Center the buttons */
  gap: 10px; /* Add space between buttons */
}

.message-list {
  position: relative;
  display: flex;
  flex-direction: column; /* Stack messages vertically */
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px; /* Ensure enough space for the button */
}

/* Message List Styling */
.message-list {
  flex: 1;
  padding: 10px 20px;
  overflow-y: auto;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

.button-container {
  position: sticky; /* Keeps the button visible while scrolling */
  bottom: 10px; /* Position near the bottom */
  text-align: center; /* Centers the button in the container */
}
