.message-form {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  .message-form input {
    flex: 1;
    padding: 5px;
  }
  
  .message-form button {
    margin-left: 10px;
    padding: 5px 10px;
  }

  .curl-command {
    display: flex;
    padding: 10px;
  }