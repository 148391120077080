.thread-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.thread-item:hover {
  background-color: #e6f7ff; /* Light blue on hover */
}

.thread-item.active {
  background-color: #bae7ff; /* Slightly darker blue for active thread */
  font-weight: bold;
}

.thread-item span {
  flex: 1;
  font-size: 14px;
  color: #333;
}
